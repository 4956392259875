import React from "react"

import { Stack, Text } from "@kiwicom/orbit-components"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import {
  DriverNumber,
  HubSupportNumber,
} from "components/generic/phone-numbers"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Emergency Procedure">
    <Hero title="Emergency Procedure" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Text>
              If there is an urgent problem that you cannot resolve (e.g. the
              bus is not charged and you are due to depart imminently), you
              should call the Driver Number (
              <DriverNumber />
              ). We'll answer the phone 24 hours a day. At night, you might need
              to try a couple of times before you get through.
            </Text>
            <Text>
              For assistance or advice with minor issues, Hub Support are
              usually available to support. They can be reached on the Hub
              Support Number (<HubSupportNumber />
              ).
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
